import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { useContentType, useSeriesTitle } from '../hooks/seriesHooks'
import { css } from '@emotion/react'
import IconButton from '../../../RadioUI/IconButton'
import EpisodeMenu from '../../../components/EpisodeMenu/EpisodeMenu'
import { useEpisodeAssociatedSeasonId, useEpisodeDuration, useEpisodeId, useEpisodeTitle } from '../hooks/episodeHooks'
import { createEpisodeShareUrl } from '../Components/EpisodeContainer'
import { TARGET_TYPE_EPISODE } from '../../../common/types'

const noop = () => {}

const EpisodeMenuWrapper = ({
  onShareContent = noop,
  onViewQueue = noop,
  onRemoveFromQueue = noop,
  onUnFavourite = noop,
  onFavourite = noop,
  onUnMarkCompleted = noop,
  onMarkCompleted = noop,
  onChangeQueue = noop,
  onClickDuplicate = noop,
  onClickTitle = noop,
  ...restProps
}) => {
  const [isHiddenState, setIsHiddenState] = useState(true)
  const { seriesId } = useParams()
  const episodeId = useEpisodeId()
  const contentType = useContentType()
  const currentEpisodeTitle = useEpisodeTitle()
  const currentEpisodeDuration = useEpisodeDuration()
  const associatedSeasonId = useEpisodeAssociatedSeasonId()
  const seriesTitle = useSeriesTitle()

  return (
    <>
      <IconButton
        aria-label="Åpne episode menyen"
        iconId="nrk-more"
        css={css`
          svg {
            filter: drop-shadow(1px 1px 1.5px rgb(0 0 0 / 0.5));
          }
        `}
        mt="4px"
        mb="4px"
        borderRadius="4px"
        variant="transparent"
        onClick={e => {
          e.preventDefault()
          setIsHiddenState(!isHiddenState)
        }}
        {...restProps}
      />
      <EpisodeMenu
        isHiddenState={isHiddenState}
        setIsHiddenState={setIsHiddenState}
        seriesId={seriesId}
        seriesTitle={seriesTitle}
        episodeId={episodeId}
        episodeTitle={currentEpisodeTitle}
        targetType={
          episodeId && contentType === 'podcast' ? TARGET_TYPE_EPISODE.EPISODE[1] : TARGET_TYPE_EPISODE.EPISODE[0]
        }
        linkUrl={createEpisodeShareUrl(contentType, seriesId, associatedSeasonId, episodeId)}
        duration={currentEpisodeDuration}
        onShareContent={onShareContent}
        onViewQueue={onViewQueue}
        onRemoveFromQueue={onRemoveFromQueue}
        onUnFavourite={onUnFavourite}
        onFavourite={onFavourite}
        onUnMarkCompleted={onUnMarkCompleted}
        onMarkCompleted={onMarkCompleted}
        onChangeQueue={onChangeQueue}
        onClickDuplicate={onClickDuplicate}
        onClickTitle={onClickTitle}
      />
    </>
  )
}

EpisodeMenuWrapper.propTypes = {
  episode: PropTypes.bool,
  onShareContent: PropTypes.func,
  onViewQueue: PropTypes.func,
  onRemoveFromQueue: PropTypes.func,
  onUnFavourite: PropTypes.func,
  onFavourite: PropTypes.func,
  onUnMarkCompleted: PropTypes.func,
  onMarkCompleted: PropTypes.func,
  onChangeQueue: PropTypes.func,
  onClickDuplicate: PropTypes.func,
  onClickTitle: PropTypes.func
}

export default EpisodeMenuWrapper
