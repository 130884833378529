import PropTypes from 'prop-types'
import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { useParams } from 'react-router-dom'
import Box from '../../../RadioUI/Box'
import Flex from '../../../RadioUI/Flex'
import LoadingPlaceholder from '../../../RadioUI/LoadingPlaceholder/LoadingPlaceholder'
import PlayProgressButton from '../../../RadioUI/PlayProgressButton/PlayProgressButton'
import PseudoBox from '../../../RadioUI/PseudoBox'
import { useColors } from '../../../RadioUI/theme/Color'
import { spCategory, trackSnowplowEvent } from '../../../clientMonitoring'
import { secondsToNorwegianMinutesPresicion } from '../../../common/formatSeconds'
import { useSetUpNextContentContext, useSetUpNextFavoriteLevel } from '../../../components/Queues/UpNext/UpNextContext'
import { usePlayerMetadata } from '../../../components/StickyPlayer/context/PlayerMetadataContext'
import { useIsPlayerPlaying } from '../../../components/StickyPlayer/hooks/useAudioAndVideoPlayerElementState'
import { useAudioOrVideoPlayerElement } from '../../../components/StickyPlayer/hooks/useAudioOrVideoPlayerElement'
import AVAILABILITY_STATUSES from '../helpers/availabilityStatuses'
import { mediaTypeFrom } from '../helpers/mediaTypeFrom'
import {
  useAvailability,
  useClipId,
  useDurationDisplayValue,
  useDurationInSeconds,
  useEpisodeDateFormatted,
  useEpisodeId,
  useEpisodeIsLoading,
  useEpisodePercentage,
  useEpisodeStartPoint,
  useEpisodeTitle
} from '../hooks/episodeHooks'
import { useSeasonIsLoading } from '../hooks/seasonHooks'
import { useContentType, useSeriesIsLoading } from '../hooks/seriesHooks'
import EpisodeMenuWrapper from './EpisodeMenuWrapper'
import EpisodeStatusIcon from './EpisodeStatusIcon'
import { useUrlTimestamp } from './UrlTimestampContext'
import VideoPodcastButton from './VideoPodcastButton'

export const createEpisodeShareUrl = (contentType, seriesId, seasonId, episodeId) => {
  if (!contentType && episodeId) {
    return `/program/${episodeId}`
  }
  if (!seriesId || !episodeId) return null
  const seasonPart = seasonId ? `/sesong/${seasonId}` : ''
  const contentTypePart = contentType === 'podcast' ? '/podkast' : '/serie'
  return `${contentTypePart}/${seriesId}${seasonPart}/${episodeId}`
}

const EpisodeContainer = forwardRef(({ contentContext, favouriteLevel, ...restProps }, ref) => {
  const { startPointFromUrl } = useUrlTimestamp()
  const episodeTitleRef = useRef()
  const isPlaying = useIsPlayerPlaying()
  const contentType = useContentType()
  const currentEpisodeTitle = useEpisodeTitle()
  const { seriesId, seasonId } = useParams()
  const episodeId = useEpisodeId()
  const clipId = useClipId()
  const episodeDateFormatted = useEpisodeDateFormatted()
  const durationDisplayValue = useDurationDisplayValue()
  const seriesIsLoading = useSeriesIsLoading()
  const seasonIsLoading = useSeasonIsLoading()
  const episodeIsLoading = useEpisodeIsLoading()
  const episodePercentage = useEpisodePercentage()
  const episodeStartPoint = useEpisodeStartPoint()
  const availability = useAvailability()
  const durationInSeconds = useDurationInSeconds()
  const colors = useColors()
  const { loadAndStartOrTogglePlayFrom } = useAudioOrVideoPlayerElement()
  const { mediaType: currentlyPlayingMediaType, episodeId: episodeIdFromMetadata } = usePlayerMetadata()
  const setUpNextContentContext = useSetUpNextContentContext()
  const setUpNextFavoriteLevel = useSetUpNextFavoriteLevel()

  const startPointFromProgress = episodePercentage === 100 ? 0 : episodeStartPoint
  const episodeIsInPlayer = episodeIdFromMetadata === episodeId
  const episodeIsPlaying = episodeIsInPlayer && isPlaying
  const episodeIsAvailable =
    availability.status === AVAILABILITY_STATUSES.AVAILABLE || availability.status === AVAILABILITY_STATUSES.EXPIRES

  useImperativeHandle(ref, () => {
    return {
      focus: () => {
        episodeTitleRef.current && episodeTitleRef.current.focus && episodeTitleRef.current.focus()
      }
    }
  })
  const actualStartPoint = startPointFromUrl || startPointFromProgress

  const onPlayPauseClick = useCallback(() => {
    setUpNextContentContext(contentContext)
    setUpNextFavoriteLevel(favouriteLevel)
    if (episodeIsPlaying) {
      trackSnowplowEvent(spCategory.SeriesPage, 'play')
    }
    loadAndStartOrTogglePlayFrom({
      episodeId,
      seasonId,
      seriesId,
      mediaType: mediaTypeFrom(contentType, currentlyPlayingMediaType, episodeIdFromMetadata, episodeId),
      seekTo: actualStartPoint
    })
  }, [
    setUpNextContentContext,
    contentContext,
    setUpNextFavoriteLevel,
    favouriteLevel,
    episodeIsPlaying,
    loadAndStartOrTogglePlayFrom,
    episodeId,
    seasonId,
    seriesId,
    contentType,
    currentlyPlayingMediaType,
    episodeIdFromMetadata,
    actualStartPoint
  ])

  const loadingDone = !seriesIsLoading && !seasonIsLoading && !episodeIsLoading

  if (!episodeId && loadingDone) return null // TODO: Placeholder

  if (!loadingDone) return <LoadingPlaceholder h="calc(60px + 1.25rem)" delay={300} {...restProps} />

  return (
    <Flex
      p="5"
      bg={colors.main.mediumLight10}
      alignItems="center"
      borderRadius="md"
      minWidth="0"
      minH="0"
      mb={4}
      {...restProps}
    >
      <Box flex="1 1 auto" order="2">
        <PseudoBox as="h2" ref={episodeTitleRef} fontWeight="600" fontSize="1.5rem">
          {currentEpisodeTitle}
        </PseudoBox>
        <Box as="p" fontSize="md">
          <Box as="span" opacity={0.7}>
            {!!clipId && `Med video • `}
            {episodeDateFormatted} •{' '}
          </Box>
          {startPointFromUrl ? (
            <Box as="span" color="#FFF7A3">
              Spiller av fra {secondsToNorwegianMinutesPresicion(startPointFromUrl)}
            </Box>
          ) : (
            durationDisplayValue
          )}
        </Box>
      </Box>
      <Box w="80px" pr="5" textAlign="center" order="1">
        {episodeIsAvailable ? (
          <PlayProgressButton
            onClick={onPlayPauseClick}
            percentage={
              startPointFromUrl && durationInSeconds ? (startPointFromUrl / durationInSeconds) * 100 : episodePercentage
            }
            isPlaying={episodeIsPlaying}
            aria-label={episodeIsPlaying ? 'Pause' : 'Spill av'}
          />
        ) : (
          <EpisodeStatusIcon availabilityStatus={availability.status} />
        )}
      </Box>
      <Flex d={{ base: 'none', sm: 'none', md: 'flex' }} alignItems="center" justifyContent="center" order="3">
        <VideoPodcastButton
          clipId={clipId}
          episodeId={episodeId}
          seasonId={seasonId}
          seriesId={seriesId}
          seekTo={actualStartPoint}
        />
        <EpisodeMenuWrapper />
      </Flex>
    </Flex>
  )
})

EpisodeContainer.displayName = 'EpisodeContainer'

EpisodeContainer.propTypes = {
  contentContext: PropTypes.string,
  favouriteLevel: PropTypes.string
}

export default EpisodeContainer
