import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import urlTimestampToSeconds from '../../../common/urlTimestampToSeconds'

const UrlTimestampContext = createContext({})

export const UrlTimestampProvider = ({ children }) => {
  const [startPointFromUrl, setStartPointFromUrl] = useState(null)
  const location = useLocation()

  useEffect(() => {
    const parsedStartPointFromUrl = urlTimestampToSeconds(location)
    if (parsedStartPointFromUrl > 0) {
      setStartPointFromUrl(parsedStartPointFromUrl)
    } else {
      setStartPointFromUrl(null)
    }
    return () => {
      setStartPointFromUrl(null)
    }
  }, [location])

  const value = useMemo(() => {
    return { startPointFromUrl }
  }, [startPointFromUrl])
  return <UrlTimestampContext.Provider value={value}>{children}</UrlTimestampContext.Provider>
}

UrlTimestampProvider.propTypes = {
  children: PropTypes.node
}

export const withUrlTimestampProvider = WrappedComponent => {
  const UrlTimestampHOC = (...props) => {
    return (
      <UrlTimestampProvider>
        <WrappedComponent {...props} />
      </UrlTimestampProvider>
    )
  }

  return UrlTimestampHOC
}

export const useUrlTimestamp = () => {
  const context = useContext(UrlTimestampContext)
  return context
}
