import { MEDIA_TYPES } from '../../../components/StickyPlayer/helpers/mediaTypes'

export const mediaTypeFrom = (contentType, currentlyPlayingMediaType, currentlyPlayingEpisodeId, episodeId) => {
  if (currentlyPlayingEpisodeId === episodeId) {
    return currentlyPlayingMediaType
  } else if (contentType === 'podcast') {
    return MEDIA_TYPES.PODCAST
  } else {
    return MEDIA_TYPES.PROGRAM
  }
}
