import _get from 'lodash/get'

const isTimestamp = hash => typeof hash === 'string' && hash.startsWith('#t=')

const parse = timestamp => {
  const hoursMatch = /\d*h/.exec(timestamp)
  const minutesMatch = /\d*m/.exec(timestamp)
  const secondsMatch = /\d*(?:\.\d{1,4})?s/.exec(timestamp)

  return {
    hours: hoursMatch ? parseInt(hoursMatch[0], 10) : 0,
    minutes: minutesMatch ? parseInt(minutesMatch[0], 10) : 0,
    seconds: secondsMatch ? parseFloat(secondsMatch[0]) : 0
  }
}

const toSeconds = ({ hours, minutes, seconds }) => {
  return hours * 3600 + minutes * 60 + seconds
}

export default location => {
  const hash = _get(location, 'hash')

  if (!hash || !isTimestamp(hash)) {
    return null
  }
  return toSeconds(parse(hash))
}
