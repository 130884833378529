import React from 'react'
import PropTypes from 'prop-types'
import AVAILABILITY_STATUSES from '../helpers/availabilityStatuses'
import Icon from '../../../RadioUI/Icon'

const EpisodeStatusIcon = ({ availabilityStatus, ...restProps }) => {
  if ([AVAILABILITY_STATUSES.NOT_AVAILABLE, AVAILABILITY_STATUSES.EXPIRED].includes(availabilityStatus)) {
    return (
      <Icon
        size="6"
        aria-label="Episode er ikke tilgjengelig lenger"
        iconId="nrk-media-tilgjengelighet-ikkelengertilgjengelig"
        {...restProps}
      />
    )
  } else if (availabilityStatus === AVAILABILITY_STATUSES.COMING) {
    return <Icon size="6" aria-label="Episode kommer" iconId="nrk-media-tilgjengelighet-kommer" {...restProps} />
  }
  return null
}

EpisodeStatusIcon.propTypes = {
  availabilityStatus: PropTypes.string
}

export default EpisodeStatusIcon
