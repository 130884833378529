import ProptTypes from 'prop-types'
import React from 'react'
import Button from '../../../RadioUI/CustomButton'
import Icon from '../../../RadioUI/Icon'
import { useStickyPlayer } from '../../../components/StickyPlayer/context/StickyPlayerContext'
import { MEDIA_TYPES } from '../../../components/StickyPlayer/helpers/mediaTypes'
import { useAudioOrVideoPlayerElement } from '../../../components/StickyPlayer/hooks/useAudioOrVideoPlayerElement'

const VideoPodcastButton = ({ clipId, episodeId, seasonId, seriesId, seekTo }) => {
  const { openFullscreen } = useStickyPlayer()
  const { loadAndStartOrPlayFrom } = useAudioOrVideoPlayerElement()

  if (!clipId) return null

  return (
    <Button
      p="0"
      h="30px"
      w="30px"
      variant="transparent"
      _hover={{ outline: 'none', background: 'transparent' }}
      _active={{ outline: 'none', background: 'transparent' }}
      aria-label="Start video"
      onClick={() => {
        openFullscreen()
        loadAndStartOrPlayFrom({ clipId, episodeId, seasonId, seriesId, mediaType: MEDIA_TYPES.CLIP, seekTo })
      }}
    >
      <Icon iconId="nrk-hardware-tv" />
    </Button>
  )
}

VideoPodcastButton.propTypes = {
  clipId: ProptTypes.string,
  episodeId: ProptTypes.string,
  seasonId: ProptTypes.string,
  seriesId: ProptTypes.string,
  mediaType: ProptTypes.string,
  seekTo: ProptTypes.number
}

export default VideoPodcastButton
